<template>
    <div class="purchase-container">
        <Card class="h-full">
            <h5 class="text-lg font-semibold">Rate adjustment</h5>
            <div class="flex justify-center" v-if="listLoading">
                <Spinner />
            </div>
            <div class="table-container" v-else>
                <table id="template-table" class="w-full table-auto">
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Business Name</th>
                            <th>Adjusted rate</th>
                            <th>Zilla rate</th>
                            <th>Created</th>
                            <th>Expires</th>
                            <th>Currency</th>
                            <th>Status</th>
                            <!-- <th>Mode</th> -->
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <EmptyState class="mx-auto w-full my-10" description="No records found"
                            v-if="discountList.length === 0" />
                        <tr v-for="(data, index) in discountList" :key="data.wireRateAdjustmentId">
                            <td>{{ index + 1 }}</td>
                            <td class="capitalize text-elipsis break-all">{{ data.wireAccountName }}</td>
                            <td>{{ data.adjustedRate | formatMoney(data.sourceCurrencyCode) }}</td>
                            <td class="capitalise text-sm">{{ data.zillaRate | formatMoney(data.sourceCurrencyCode)}}</td>
                            <td class="capitalize text-sm">{{ dayjs(data.createdAt).format("MMM D YYYY | hh:mmA")}}</td>
                            <td class="break-all">{{ dayjs(data.expiresAt).format("MMM D YYYY | hh:mmA") }}</td>
                            <td>{{ data.sourceCurrencyCode}} / {{data.destinationCurrencyCode}}</td>
                            <td>{{ data.status}}</td>
                            <!-- <td>{{ dayjs(data.createdAt).format("MMM D YYYY | hh:mmA") }}</td> -->
                            <td>
                                <!-- <div class="grid grid-cols-2"> -->
                                    <SecondaryButton text="Deactivate" width="w-24" @click="confirmDeactivate(data)"

                                    v-if="data.status !== 'IN_ACTIVE'"
                                        />
                                    <!-- <SecondaryButton text="View Kyb" :disabled="!data.businessRegistrationInfoId" width="w-24" @click="updateKybModal(data)" /> -->
                                    <!-- <SecondaryButton text="View Acc." width="w-24"
                                        @click="$router.push(`/wire/account/${data.id}`)" /> -->
                                <!-- </div> -->
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </Card>
        <Modal :display="showDeactivateDiscountModal" @close="showDeactivateDiscountModal = false">
            <p class="text-lg font-semibold">Are you sure you want to deactivate this rate discount</p>
            <div class="flex items-center justify-center mt-8">
                <SecondaryButton text="Cancel" class="mr-3" width="w-24" @click="showDeactivateDiscountModal = false"/>
                <Button width="w-44" text="Deactivate" :loading="deactivateLoading" @click="deactivateDiscount"/>
            </div>
        </Modal>
    </div>
</template>
<script>
import {GET_RATE_ADJUSTMENTS_LIST, DEACTIVATE_RATE_DISCOUNT, CREATE_RATE_DISCOUNT} from '@/utils/api/wire'
import Spinner from '@/UI/Spinner'
import {SecondaryButton, Button} from '@/UI/Button'
import dayjs from 'dayjs'
import {Modal} from '@/UI/Modals'
export default {
    components:{
        Spinner,
        SecondaryButton,
        Button,
        EmptyState: () => import("@/UI/EmptyState"),
        Modal
    },
    data:() => ({
        discountList:[],
        listLoading: false,
        showDeactivateDiscountModal: false,
        dataToDeactivate: {},
        deactivateLoading: false
    }),
    computed: {
        dayjs() {
            return dayjs
        }
    },
    methods:{
        getAdjustmentLists(){
            this.listLoading = true;
            GET_RATE_ADJUSTMENTS_LIST({}).then(({data}) => {
                this.discountList = data.data
                this.listLoading = false
            }).catch(() => {
                this.listLoading = false
            })
        },
        confirmDeactivate(data){
            this.showDeactivateDiscountModal = true;
            this.dataToDeactivate = data
        },
        deactivateDiscount(){
            this.deactivateLoading = true;
            const data ={
                wireRateAdjustmentId: this.dataToDeactivate.wireRateAdjustmentId
            }
            DEACTIVATE_RATE_DISCOUNT(data).then(() => {
                this.getAdjustmentLists()
                this.showDeactivateDiscountModal = false;
                this.deactivateLoading = false
            }).catch(() => {
                this.deactivateLoading = false
            })
        }
    },
    mounted(){
        this.getAdjustmentLists()
    }
}
</script>
<style scoped>
.purchase-container {
    min-height: calc(100vh - 10rem);
}

.searchContainer {
    border: 1px solid rgba(138, 116, 192, 0.1) !important;
    width: 12rem;
}
</style>