<template>
    <RateAdjustment/>
</template>
<script>
import RateAdjustment from '@/components/Wire/RateAdjustment'
export default {
    components:{
        RateAdjustment
    }
}
</script>